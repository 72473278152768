<template>
    <div>
        <div :class="{ questionComponent: size == 1, 'pl24 homework': pageType == 2, knowledgeTest: pageType == 4 }"
            :id="info.sheetIndex">
            <div style="position: relative">
                <div :class="{ 'mb32 ml5 questionTitle': true, line: info.questionType == 5, titleHidden: pageType == 2 && isShowUnfold && !isUnfold }"
                    ref="questionTitleRef" v-if="info.questionType != 999">
                    <div class="title" v-html="info.questionComposeTitle"></div>

                </div>
                <div class="title-unfold-mask" v-if="pageType == 2 && isShowUnfold && !isUnfold"></div>
            </div>
            <!-- 题目 -->
            <div style="position: relative">
                <div :class="{ 'mb32 ml5 questionTitle': true, line: info.questionType == 5, titleHidden: pageType == 2 && isShowUnfold && !isUnfold }"
                    ref="questionTitleRef" v-if="info.questionType != 999">
                    <div style="display: flex;flex-wrap: wrap;align-items:baseline;">
                        <div style="margin-right: 5px;color: #FF5D51;">{{ info.questionindex }}、 {{
                            queType[Number(info.questionType)]
                            }}</div>
                        <div class="title" v-html="info.questionTitle"></div>
                    </div>

                    <el-image :src="info.questionImage" class="title-img" :preview-src-list="[info.questionImage]"
                        fit="cover" v-if="info.questionImage" />
                    <audio :src="info.questionAudio" controls class="title-audio" v-if="info.questionAudio" />
                    <video :src="info.questionVideo" controls class="title-video" v-if="info.questionVideo" />
                </div>
                <div class="title-unfold-mask" v-if="pageType == 2 && isShowUnfold && !isUnfold"></div>
            </div>
            <slot name="title" />
            <!-- 单选题 -->
            <template v-if="info.questionType == 0">
                <div :class="{
                    'answer-option': true,
                    'answer-userAnswer': !(isSubmit || info.isShowAnswer) && info.questionUserAnswer == String.fromCharCode(65 + index),
                    'answer-right': (isSubmit || info.isShowAnswer) && info.questionAnswer == String.fromCharCode(65 + index),
                    'answer-wrong': (isSubmit || info.isShowAnswer) && info.questionUserAnswer == String.fromCharCode(65 + index) && info.questionAnswer != String.fromCharCode(65 + index),
                }" v-for="(item, index) in info.questionOption" :key="index"
                    @click="singleChoice(String.fromCharCode(65 + index))">
                    <div class="answer-i mr10 pointer">
                        <el-icon class="answer-check">
                            <Check />
                        </el-icon>
                        <el-icon class="answer-error">
                            <Close />
                        </el-icon>
                    </div>
                    <div class="answer-option-item pointer">{{ String.fromCharCode(65 + index) }}.</div>
                    <div class="answer-option-item answer-option-item-content ml5 pointer" v-html="item"></div>
                </div>
            </template>

            <!-- 多选题 不定项选择题 -->
            <template v-if="[1, 6].includes(Number(info.questionType))">
                <div :class="{
                    'answer-option': true,
                    'answer-userAnswer': !(isSubmit || info.isShowAnswer) && info.questionUserAnswer && info.questionUserAnswer.includes(String.fromCharCode(65 + index)),
                    'answer-right': (isSubmit || info.isShowAnswer) && info.questionAnswer && info.questionAnswer.includes(String.fromCharCode(65 + index)),
                    'answer-wrong': (isSubmit || info.isShowAnswer) && info.questionUserAnswer && info.questionUserAnswer.includes(String.fromCharCode(65 + index)) && !info.questionAnswer.includes(String.fromCharCode(65 + index)),
                }" v-for="(item, index) in info.questionOption" :key="index"
                    @click="multipleChoice(String.fromCharCode(65 + index))">
                    <div class="answer-i mr10 pointer">
                        <el-icon class="answer-check">
                            <Check />
                        </el-icon>
                        <el-icon class="answer-error">
                            <Close />
                        </el-icon>
                    </div>
                    <div class="answer-option-item pointer">{{ String.fromCharCode(65 + index) }}.</div>
                    <div class="answer-option-item ml5 pointer" v-html="item"></div>
                </div>
            </template>

            <!-- 判断题 -->
            <template v-if="info.questionType == 2">
                <div :class="{
                    'answer-option': true,
                    'answer-userAnswer': !(isSubmit || info.isShowAnswer) && info.questionUserAnswer == String.fromCharCode(65 + index),
                    'answer-right': (isSubmit || info.isShowAnswer) && info.questionAnswer == String.fromCharCode(65 + index),
                    'answer-wrong': (isSubmit || info.isShowAnswer) && info.questionUserAnswer == String.fromCharCode(65 + index) && info.questionAnswer != String.fromCharCode(65 + index),
                }" v-for="(item, index) in judgeOption" :key="index"
                    @click="singleChoice(String.fromCharCode(65 + index))">
                    <div class="answer-i mr10 pointer">
                        <el-icon class="answer-check">
                            <Check />
                        </el-icon>
                        <el-icon class="answer-error">
                            <Close />
                        </el-icon>
                    </div>
                    <div class="answer-option-item pointer">{{ String.fromCharCode(65 + index) }}.</div>
                    <div class="answer-option-item ml5 pointer" v-html="item"></div>
                </div>
            </template>

            <!-- 填空题 简答题 文件 -->
            <template v-if="[3, 4, 999].includes(Number(info.questionType))">
                <div v-if="!info.isShowAnswer" class="textarea-box mb20">
                    <el-input v-model="info.questionUserAnswer" placeholder="请输入您的答案~" resize="none" type="textarea"
                        :maxlength="maxLength" class="inputi" :disabled="isSubmit || info.isShowAnswer"
                        @input="touchWatch" />
                    <div class="wordLength" v-if="!isSubmit && !info.isShowAnswer">
                        <span>{{ info.questionUserAnswer ? info.questionUserAnswer.length : 0 }}</span>
                        <span>/{{ maxLength }}</span>
                    </div>
                </div>
                <div v-if="!info.isShowAnswer" class="upload mb20">
                    <div class="upload-img">
                        <!-- <div v-if="isSubmit" :class="{ 'upload-item': true, mr16: index != imgCount - 1 }"
                            v-for="(item, index) in info.questionUserAnswerImg" :key="index">
                            <el-image :src="item.url" fit="cover" class="upload-cover" :preview-src-list="getImgList()"
                                :initial-index="index" v-if="item.url" />
                            <img src="@/assets/img/questionNew/39.png" class="upload-del pointer"
                                v-if="!isSubmit && !info.isShowAnswer" />
                        </div> -->
                        <el-upload :disabled="info.isShowAnswer" ref="upload" :action="imgAction()" :headers="Domainjwt"
                            name="upload" :limit="4" list-type="picture-card" :before-upload="beforeAvatarUpload"
                            :file-list="fileList1" :on-success="handleAvatarSuccess" :on-remove="handleRemove">
                            <i class="el-icon-plus" />
                        </el-upload>
                    </div>
                    <div class="upload-tips" v-if="!isSubmit && !info.isShowAnswer">
                        <span>{{ info.questionUserAnswerImg.length }}</span>
                        <span>/{{ imgCount }} 点击上传（最多可上传{{ imgCount }}张）</span>
                    </div>
                </div>
            </template>

            <!-- 组合题 -->
            <template v-if="info.questionType == 5">
                <!-- <div class="mb40" v-for="(item, index) in info.childrenList" :key="index"> -->
                <!-- <Question :info="item" :size="2" :pageType="pageType" :isSubmit="isSubmit" :examStatus="examStatus"
                        :isSingle="isSingle" :isShowUserAnswer="isShowUserAnswer" :maxLength="maxLength"
                        @answerFn="answerFn" /> -->
                <!-- </div> -->
            </template>

            <!-- 专属题库刷题页 -->
            <template v-if="pageType == 3 && !info.isShowAnswer">
                <!-- 多选题、不定项-确认 -->
                <div :class="{ multipleSubmit: true, multipleSubmit2: size == 2 }"
                    v-if="[1, 6].includes(Number(info.questionType))" @click="multipleSubmit">确认</div>

                <!-- 主观题-已掌握、未掌握 -->
                <div :class="{ grasp: true, grasp2: size == 2 }" v-if="[3, 4].includes(Number(info.questionType))">
                    <div class="grasp-tips">（本题不支持系统判断正确错误，请自行判断）</div>
                    <div class="grasp-buts">
                        <div @click="master(1)">已掌握</div>
                        <div @click="master(0)">不了解</div>
                    </div>
                </div>
            </template>


        </div>

        <!-- 答案 解析 老师点评 知识点 -->
        <template
            v-if="(isSubmit || info.isShowAnswer) && (objective.includes(Number(info.questionType)) || (subjective.includes(Number(info.questionType)) && (examStatus == '4' || !isSingle)))">
            <template v-if="info.questionType != 5">
                <!-- 答案 -->
                <div :class="{ 'answer pr20 mt23': true, pl43: size == 1, pl30: size == 2 }" v-if="pageType != 2">
                    <div :class="{ 'answer-item mr70': true, 'answer-right': info.isRight, 'answer-wrong': !info.isRight, 'answer-noAnswer': !info.isAnswer }"
                        v-if="objective.includes(Number(info.questionType)) && isShowUserAnswer">
                        <div class="answer-item-status"></div>
                        <span>您的答案：</span>
                        <span class="answer-item-userAnswer" v-if="[0, 2].includes(Number(info.questionType))">{{
                            info.questionUserAnswer ? info.questionUserAnswer : "未做答" }}</span>
                        <span class="answer-item-userAnswer" v-if="[1, 6].includes(Number(info.questionType))">{{
                            info.questionUserAnswer && info.questionUserAnswer.length != 0 ?
                                info.questionUserAnswer.toString() : "未做答" }}</span>
                    </div>
                    <div class="answer-item answer-right">
                        <div class="answer-item-status"></div>
                        <span>参考答案：</span>
                        <span
                            :class="{ 'answer-item-userAnswer': true, 'subjective-answer-item-questionAnswer': subjective.includes(Number(info.questionType)) }"
                            v-html="info.questionAnswer"></span>
                    </div>
                </div>

                <!-- 试题解析 -->
                <div
                    :class="{ analysis: true, mt20: pageType != 2, 'pl43 pr34': size == 1 && pageType != 2, 'pl24 pr24 mt8': size == 1 && pageType == 2 }">
                    <div class="analysis-title mb12 pl10">试题解析</div>
                    <div class="analysis-content pl10" v-html="info.questionAnswerAnalysis"></div>
                    <el-image :src="info.questionAnswerAnalysisImage" class="title-img"
                        :preview-src-list="[info.questionAnswerAnalysisImage]"
                        v-if="info.questionAnswerAnalysisImage" />
                    <audio :src="info.questionAnswerAnalysisAudio" class="title-audio" controls
                        v-if="info.questionAnswerAnalysisAudio" />
                    <video :src="info.questionAnswerAnalysisVideo" controls class="title-video"
                        v-if="info.questionAnswerAnalysisVideo" />
                    <div class="analysis-content pl10"
                        v-if="!info.questionAnswerAnalysis && !info.questionAnswerAnalysisImage && !info.questionAnswerAnalysisAudio && !info.questionAnswerAnalysisVideo">
                        无</div>
                </div>
                <div class="jiexi">
                    <div class="answerTitle fles-shrink dis_flex">
                        <div class="bline"></div>
                        <div>知识点：</div>
                        <div class="btn" @click="examine">查看知识点</div>
                    </div>
                    <div ref="recommendBox">
                        <kowledgePoints v-show="showPoints" ref="kowledgePoints" type="3" />
                    </div>
                </div>

            </template>

            <!-- 知识点 -->
            <!-- <knowledge ref="knowledgeRef" v-if="size == 1 && !isSingle" :classType="pageType != 4 ? 2 : 5"
                :isStudyCenter="isStudyCenter" :isShowVideo="pageType != 4" @playVideo="playVideo" /> -->
        </template>

        <!-- 知识点测试做题 -->
        <div class="knowledgeTest" v-if="pageType == 4 && size == 1">
            <!-- 多选、不定项、主观题-确认 -->
            <div class="multipleSubmit" v-if="[1, 3, 4, 5, 6].includes(Number(info.questionType)) && !info.isAnswer"
                @click="multipleSubmit">提交答案</div>

            <!-- 主观题-已掌握、未掌握 -->
            <div class="grasp"
                v-if="[3, 4, 5].includes(Number(info.questionType)) && info.isAnswer && info.isGrasp === ''">
                <div class="grasp-tips">（本题不支持系统判断正确错误，请自行判断）</div>
                <div class="grasp-buts">
                    <div @click="master(1)">已掌握</div>
                    <div @click="master(0)">不了解</div>
                </div>
            </div>
        </div>


        <!-- 视频弹窗 -->
        <!-- <el-dialog width="943px" v-model="videoShow" :destroy-on-close="true" @close="videoClose">
            <ccPlayer ref="ccPlayerRef" v-if="isCc" />
            <div class="player-main" v-else>
                <jxyPlay ref="jxyPlayer" width="100%" height="100%" :url="videoInfo.playLink"
                    :lastPlayTime="Number(videoInfo.pointSec)" />
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
import { queType } from '@/api/emun'
import answerView from '@/components/Know/course/answerView.vue'
import { AnswerClient } from '@/api/task/answerClient'
import questionItem from '@/components/questionNew/questionItem.vue'


const answerClient = new AnswerClient()
import { setInfo, setToken, getInfo, getDomainToken } from '@/api/cookies'
// import kowledgePoints from '@/components/kowledgePoints.vue'
// import TaskCar from './taskCar.vue'
export default {
    name: 'TaskCar1',
    components: {

        questionItem,
        kowledgePoints: () => import('@/components/kowledgePoints.vue')
    },
    props: {
        moduleType: { type: String },
        info: {
            type: Object,
            default: () => {
                return {};
            },
        },
        // 页面类型 1正常做题页 2我的作业做题页 3专属题库刷题页 4知识点测试做题
        pageType: {
            type: Number,
            default: 1,
        },
        // 1第一层 2嵌套第二层
        size: {
            type: Number,
            default: 1,
        },
        // 是否提交
        isSubmit: {
            type: Boolean,
            default: false,
        },
        // 阅卷状态
        examStatus: {
            type: [String, Number],
            default: "0",
        },
        // 第几题
        current: {
            type: Number,
            default: 0,
        },
        // 是否为单题显示
        isSingle: {
            type: Boolean,
            default: false,
        },
        // 单题是否购买
        singleIsBuy: {
            type: Number,
            default: 0,
        },
        // 图片上传数量
        imgCount: {
            type: Number,
            default: 4,
        },
        // 是否从学习中心打开(0否1是 知识点功能使用)
        isStudyCenter: {
            type: Number,
            default: 0,
        },
        // 是否显示你的答案
        isShowUserAnswer: {
            type: Boolean,
            default: true,
        },
        // 主观题答案最大长度
        maxLength: {
            type: Number,
            default: 1000,
        },
    },
    data() {
        return {
            showPoints: false,
            // 客观题类型
            objective: [0, 1, 2, 6],
            // 主观题类型
            subjective: [3, 4, 5],
            Domainjwt: {
                Domainjwt: getDomainToken()
            },
            // 判断题选项
            judgeOption: ["正确", "错误"],
            imgId: 1,
            queType: queType,
            fileList1: []
        }
    },

    async created() {
        this.fileList1 = []
        console.log(this.info, 'this.info');

        if (this.info.questionUserAnswerImg && this.info.questionUserAnswerImg.length > 0) {
            this.info.questionUserAnswerImg.forEach(e => {
                this.fileList1.push(e)
            });
        } else {
            this.fileList1 = []
        }
    },
    watch: {
        info: {
            handler(newVal, oldVal) {
                this.fileList1 = []
                console.log(this.info, 'this.info');

                if (this.info.questionUserAnswerImg && this.info.questionUserAnswerImg.length > 0) {
                    this.info.questionUserAnswerImg.forEach(e => {
                        this.fileList1.push(e)
                    });
                } else {
                    this.fileList1 = []
                }
                console.log(this.fileList1, 'this.fileList1');

            },
            deep: true
        }
    },
    methods: {

        // 单选题选择 判断题
        singleChoice(code) {
            console.log(this.info.isShowAnswer, 'this.info.isShowAnswer');

            if (this.info.isShowAnswer) return;
            this.info.questionUserAnswer = code;
            this.info.pageSize = this.size;
            this.$emit("answerFn", this.info);
            this.$emit("watchUserAnswer");
        },

        // 多选题 不定项选择题
        multipleChoice(code) {
            if (this.info.isShowAnswer) return;
            if (this.info.questionUserAnswer) {
                const index = this.info.questionUserAnswer.findIndex((i) => i == code);
                if (index == -1) {
                    this.info.questionUserAnswer.push(code);
                } else {
                    this.info.questionUserAnswer.splice(index, 1);
                }
            } else {
                this.info.questionUserAnswer = []
                this.info.questionUserAnswer.push(code);

            }
            this.info.questionUserAnswer = this.info.questionUserAnswer.sort();
            this.$emit("watchUserAnswer");
        },
        // 多选题、不定项题-确认
        multipleSubmit() {
            this.$emit("answerFn", this.info);
        },
        touchWatch() {
            this.$emit("watchUserAnswer");
        },
        // 专属题库刷题页-是否掌握
        master(type) {
            this.info.isGrasp = type;
            this.$emit("answerFn", this.info);
        },
        delImg(index) {
            this.questionUserAnswerImg.splice(index, 1);
            this.$emit("watchUserAnswer");
        },
        getImgList() {
            return this.info.questionUserAnswerImg.map((i) => i.url);
        },
        // this.$refs.upload
        // 图片



        beforeAvatarUpload(file) {
            const allowImgType = ['image/jpeg', 'image/png', 'image/gif']
            if (!allowImgType.includes(file.type)) {
                this.$message.error('上传文件类型不符,仅支持jpg/png/gif格式图片')
                return false
            }
        },
        // 图片上传
        handleAvatarSuccess(res, file, fileList) {
            if (res.isSuccess) {
                if (!this.info.questionUserAnswerImg) {
                    this.info.questionUserAnswerImg = []
                }
                const data = { url: res.path, name: '' }
                this.info.questionUserAnswerImg.push(data)
            }
        },
        handleRemove(file, fileList) {
            this.info.questionUserAnswerImg = this.info.questionUserAnswerImg.filter((item) => {
                return item.url != file.url
            })


            this.$forceUpdate()
        },
        examine() {
            const that = this
            this.$nextTick(() => {
                this.showPoints = !this.showPoints
                if (this.showPoints) {
                    that.$refs.kowledgePoints.getData(this.info.subjectId)
                    // that.$refs.kowledgePoints.getData('1445594466500468810')
                }
            })
        },



    }
}
</script>
<style lang="less" scoped>
.questionComponent {
    padding-top: 26px;
    padding-left: 43px;
    padding-right: 22px;
    position: relative;
}

.line {
    border-bottom: 1px dashed #eee;
    padding-bottom: 20px;
    margin-bottom: 20px !important;
}

.questionTitle {
    position: relative;
}

.titleHidden {
    height: 170px;
    overflow-y: scroll;
}

.title-unfold-mask {
    width: 100%;
    height: 20px;
    background-color: #fff;
    filter: blur(3px);
    position: absolute;
    bottom: -15px;
    left: 0;
}

.title {
    font-size: 16px;
    color: #333;
    line-height: 30px;

    .questionType {
        color: #FF5D51;
        margin-right: 5px;
    }
}

.title-img {
    width: 200px;
    height: 200px;
    margin: 20px 0;
    display: block;
    border-radius: 10px;
}

.title-audio {
    margin: 20px 0;
}

.title-video {
    width: 100%;
    height: 300px;
    margin: 20px 0;
}

.title-unfold {
    width: 130px;
    height: 36px;
    border-radius: 19px;
    background-color: #f7f7fa;
    margin: 0 auto;
    font-size: 14px;
    color: #777;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 8px;
        transition: all 0.3s;
    }

    .unfoldDown {
        transform: rotate(180deg);
    }
}

.answer-option {
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
    margin-bottom: 22px;
}

.answer-i {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #999;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    color: #fff;
    position: relative;

    .answer-check {
        display: none;
    }

    .answer-error {
        display: none;
    }
}

.answer-userAnswer {
    .answer-i {
        border-color: #FF5D51;
        background-color: #FF5D51;

        &::after {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.answer-right {
    color: #34b851;

    .answer-i {
        border-color: #34b851;
        background-color: #34b851;

        .answer-check {
            display: inline-block;
        }
    }
}

.answer-wrong {
    color: #ff3030;

    .answer-i {
        border-color: #ff3030;
        background-color: #ff3030;

        .answer-error {
            display: inline-block;
        }
    }
}

.answer-option-item {
    display: inline-block;
}

.answer-option-item-content {
    flex: 1;
    flex-wrap: wrap;
}

.sizeTwo-option-item {
    color: #FF5D51 !important;
}

.inputi {
    height: 180px;

    :deep(textarea) {
        height: 100%;
        padding: 14px 16px 0;
        font-size: 15px;
        color: #333 !important;
        box-shadow: none !important;
        background-color: #f7f7fa !important;
        word-break: break-all;
    }

    :deep(.el-input__count) {
        bottom: 14px;
        right: 16px;
    }
}

.textarea-box {
    position: relative;
    background-color: #f7f7fa;
    border-radius: 8px;
    overflow: hidden;

    .wordLength {
        font-size: 14px;
        color: #777;
        padding: 5px 16px 14px;
        text-align: right;

        span:first-child {
            color: #FF5D51;
        }
    }
}

.upload {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    .upload-img {
        display: flex;
    }

    .upload-item {
        width: 110px;
        height: 110px;
        position: relative;

        .upload-cover {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }

        .upload-del {
            width: 24px;
            height: 24px;
            position: absolute;
            top: -12px;
            right: -12px;
        }
    }

    .upload-tips {
        font-size: 14px;
        color: #aaa;
        margin-left: 14px;

        span:first-child {
            color: #FF5D51;
        }
    }

    .fileInput {
        opacity: 0;
        display: none;
    }
}

.answer {
    width: 100%;
    padding: 24px 0;
    background-color: #f9fbff;
    display: flex;
    align-items: flex-start;
    border-top: 1px dashed #eee;

    .answer-item {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333;

        .answer-item-status {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 5px;
            // transform: translateY(6px);
        }

        .answer-item-userAnswer {
            font-weight: bold;
        }

        &:last-child {
            .answer-item-userAnswer {
                flex: 1;
            }
        }

        .subjective-answer-item-questionAnswer {
            color: #333 !important;
            font-weight: normal !important;
        }
    }

    .answer-right {
        .answer-item-status {
            background-color: #34b851;
        }

        .answer-item-userAnswer {
            color: #34b851;
        }
    }

    .answer-wrong {
        .answer-item-status {
            background-color: #ff3030;
        }

        .answer-item-userAnswer {
            color: #ff3030;
        }
    }

    .answer-noAnswer {
        .answer-item-status {
            background-color: #aaa;
        }

        .answer-item-userAnswer {
            color: #aaa;
        }
    }
}

.analysis {
    .analysis-title {
        position: relative;
        color: #333;
        font-size: 14px;
        font-weight: bold;

        &::after {
            content: "";
            width: 4px;
            height: 14px;
            background-color: #FF5D51;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 1px;
        }
    }

    .analysis-content {
        font-size: 14px;
        color: #777;
    }
}

.player-main {
    width: 100%;
    height: 495px;
    position: relative;
    background: #2e2e2e;
}

.homework {
    .upload {
        display: block;
    }

    .upload-item {
        width: 76px;
        height: 76px;

        .upload-del {
            width: 16px;
            height: 16px;
            top: -8px;
            right: -8px;
        }
    }

    .upload-tips {
        margin-left: 0;
        margin-top: 12px;
    }
}

.record {
    .record-title {
        font-size: 16px;
        color: #333;
        position: relative;

        &::before {
            content: "";
            width: 4px;
            height: 12px;
            background-color: #FF5D51;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .record-flex {
        display: flex;
        align-items: flex-end;
    }

    .record-but {
        width: 144px;
        height: 40px;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        img {
            width: 13px;
            height: 18px;
            margin-right: 6px;
        }
    }

    .record-start {
        background: linear-gradient(180deg, #97b2ff 0%, #3067ff 100%);
    }

    .record-ing {
        background: linear-gradient(180deg, #ffa997 0%, #ff3030 100%);
    }

    .record-tips {
        font-size: 14px;
        color: #aaa;

        span:first-child {
            color: #FF5D51;
        }
    }
}

.quiz {
    font-size: 14px;

    .quiz-info {
        display: flex;
        align-items: center;

        .quiz-icon {
            width: 19px;
            height: 19px;
            margin-right: 10px;
        }

        .quiz-name {
            color: #333;
        }

        .quiz-time {
            color: #999;
        }
    }

    .quiz-content {
        color: #777;
        line-height: 26px;
        word-break: break-all;
    }

    .quiz-img {
        display: flex;
        flex-wrap: wrap;

        .quiz-img-item {
            width: 76px;
            height: 76px;
            border-radius: 8px;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.multipleSubmit {
    width: 200px;
    height: 50px;
    border-radius: 6px;
    background: linear-gradient(180deg, #97b2ff 0%, #3067ff 100%);
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    margin: 58px auto;
    cursor: pointer;
}

.multipleSubmit2 {
    margin: 0 auto;
}

.grasp {
    margin-top: 76px;
    margin-bottom: 20px;

    .grasp-tips {
        text-align: center;
        font-size: 14px;
        color: #aaa;
        margin-bottom: 20px;
    }

    .grasp-buts {
        display: flex;
        justify-content: center;

        div {
            width: 243px;
            height: 49px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            color: #333;
            border: 1px solid #ebebeb;
            border-radius: 8px;
            margin: 0 20px;
            cursor: pointer;
        }
    }
}

.grasp2 {
    margin-top: 0;
}

.teacher-img {
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;

    .teacher-img-cover {
        width: 76px;
        height: 76px;
        border-radius: 8px;
        margin-right: 16px;
        margin-bottom: 10px;
    }
}

.knowledgeTest {
    .multipleSubmit {
        margin: 42px auto;
    }

    .grasp {
        margin-top: 44px;
    }
}

.jiexi {
    width: 860px;
    padding-left: 43px;

    .answerTitle {
        margin: 40px 0px 20px;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 12px;

        .bline {
            width: 4px;
            height: 14px;
            background: #ff5d51;
            border-radius: 2px;
            margin-right: 6px;
        }

        .btn {
            font-size: 10px;
            padding: 2px 10px;
            border-radius: 8px;
            background-color: #ff5d51;
            color: #ffffff;
            cursor: pointer;
        }
    }

    .dis_flex {
        display: flex;
        align-items: center;
    }

    .jiexi-text {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 26px;

        .imgBox {
            margin-top: 20px;

            img {
                width: 100px;
                height: 100px;
                margin-right: 10px;
            }
        }
    }
}

::v-deep .el-textarea__inner {
    border: none !important;
}
</style>